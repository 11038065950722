<template>
  <div class="Register content text-blue">

    <div>
      <go to="/register" class="h1 float-right">
        ×
      </go>
      {{ $t('terms.title') }}
    </div>

    <div class="font-normal" v-html="$t('terms.text')" />

  </div>
</template>

<script>
export default {
  mounted() {
    document.body.classList.add('bg-gray');
  },
  unmounted() {
    document.body.classList.remove('bg-gray');
  },
};
</script>
